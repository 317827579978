import React,{ useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'


function Navbar() {
  const navRef = useRef();
  const  [navCondition,setNavCondition] = useState(false);

  function toggleNav(condition){
    navCondition === true ? setNavCondition(false) : setNavCondition(true);
  }

  return (

    <nav className="{&quot;flex" px-4="" border-b-gray-900="" items-center="" relative="" open-"="" +="" navCondition}="">
        <div style="{{zIndex:1000}}" className="flex text-lg font-bold md:py-0 py-4 z-600">
          <navlink to="/">  <img className="logoIcon" src="/img/af-films-logo.svg" alt=""></navlink>
        </div>
        <ul ref="{navRef}" className="{" 'nav="" nav-'="" +="" navCondition="" +"="" md:px-2="" ml-auto="" md:flex="" md:space-x-2="" absolute="" md:relative="" top-full="" left-0="" right-0="" z-200"}="">
            <navlink onClick="{()" ==""> setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800" to={"/"}>
            <li>

                    <span className="text-white uppercase glowTitle">Home</span>

            </li>
            </navlink>

            <navlink onClick="{()" ==""> setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800" to={"/tv"}>
            <li>

                    <span className="text-white uppercase glowTitle">TV</span>

            </li>
            </navlink>

            <navlink onClick="{()" ==""> setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800 nav" to={"/cine"}>
            <li>

                    <span className="text-white uppercase glowTitle">Cinema</span>

            </li>
            </navlink>

            <navlink onClick="{()" ==""> setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800 nav" to={"/documentales"}>
            <li>

                    <span className="text-white uppercase glowTitle">Documentaries</span>

            </li>
            </navlink>

            <navlink onClick="{()" ==""> setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800 nav" to={"/noticias"}>
            <li>

                    <span className="text-white uppercase glowTitle">News</span>

            </li>
            </navlink>



            <navlink onClick="{()" ==""> setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800" to={"/contacto"}>
            <li>

                    <span className="text-white uppercase glowTitle">Contact</span>

            </li>
            </navlink>
        </ul>
        <div style="{{zIndex:1000}}" className="ml-auto md:hidden text-white cursor-pointer">
          <a onClick="{()" ==""> toggleNav() } href="#nav">
            <svg className="{&quot;w-5" h-5="" fill-current="" navBtn="" open-"="" +="" navCondition}="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg>
          </a>
      </div>
    </nav>

  )
}

export default Navbar
