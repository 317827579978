//https://github.com/vandit-bera/React-Routing
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorPage from './component/ErrorPage';
import Navbar from "./component/Navbar";
import App from './App';
import Legal from "./component/Legal";
import Blog from "./component/Blog";
import Contact from "./component/Contact";
import Home from "./component/Home";
import Services from "./component/Services";
import Team from "./component/Team";
//import WorkView from "./component/WorkView";
import WorkView_1 from "./component/WorkView_1";
import WorkDoc from "./component/WorkDoc";
import WorkCine from "./component/WorkCine";
import WorkTv from "./component/WorkTv";
import News from "./component/News";
import NewsView from "./component/NewsView";
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import ThemeProvider from './context/ThemeProvider';

const fetchProjectsHome = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-all');
return await response.json();
};
const fetchProjectsCine = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-cine');
return await response.json();
};
const fetchProjectsTv = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-tv');
return await response.json();
};
const fetchProjectsDoc = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-doc');
return await response.json();
};
const fetchNews = async () => {
const response = await fetch('https://discover.frankariza.com/api/v1/blog');
return await response.json();
};


const router = createBrowserRouter([
  {
    element: <app></app>,
     errorElement: <errorpage transition="{`${'fadeIn'}`}/">,
    children: [
  {
    path: "/",
    element: <home transition="{`${'fadeIn'}`}"></home>,
    loader: fetchProjectsHome,
  },
  {
    path: "/cine",
    element: <workcine title="{'CINEMA'}" transition="{`${'fadeIn'}`}"></workcine>,
    loader: fetchProjectsCine,
    children: [
      {
        path: ":id",
        element: <workview_1 transition="{`${'fadeIn'}`}"></workview_1>,
        loader: fetchProjectsCinema,
        errorElement: <errorpage transition="{`${'fadeIn'}`}"></errorpage>,
      },
    ],
  },
  {
    path: "/documentales",
    element: <workdoc title="{'documentaries'}" transition="{`${'fadeIn'}`}"></workdoc>,
    loader: fetchProjectsDoc,
    children: [
      {
        path: ":id",
        element: <workview_1 transition="{`${'fadeIn'}`}"></workview_1>,
        loader: fetchProjectsDoc,
        errorElement: <errorpage transition="{`${'fadeIn'}`}"></errorpage>,
      },
    ],
  },
  {
    path: "/tv",
    element: <worktv title="{'TV'}" transition="{`${'fadeIn'}`}"></worktv>,
    loader: fetchProjectsTv,
    children: [
      {
        path: ":id",
        element: <workview_1 transition="{`${'fadeIn'}`}"></workview_1>,
        loader: fetchProjectsTv,
        errorElement: <errorpage transition="{`${'fadeIn'}`}"></errorpage>,
      },
    ],
  },
  {
    path: "/noticias",
    element: <news title="{'NEWS'}" transition="{`${'fadeIn'}`}"></news>,
    loader: fetchNews,
    children: [
      {
        path: ":id",
        element: <newsview transition="{`${'fadeIn'}`}"></newsview>,
        loader: fetchNews,
        errorElement: <errorpage transition="{`${'fadeIn'}`}"></errorpage>,
      },
    ],
  },
  {
    path: "/servicios",
    element: <services transition="{`${'fadeIn'}`}"></services>,
  },
  {
    path: "/contacto",
    element: <contact transition="{`${'fadeIn'}`}"></contact>,
  },
  {
    path: "/legal",
    element: <legal transition="{`${'fadeIn'}`}"></legal>,
  },
]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <react.strictmode>
  <themeprovider>

    <routerprovider router="{router}" future="{{" v7_startTransition:="" true="" }}="">
<navbar></navbar>
    </routerprovider>
    </themeprovider>
  </react.strictmode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
</errorpage>