import React,{ useState, useEffect, useContext } from 'react';
import { Outlet } from "react-router-dom";
//import translateText from './api/GoogleTranslate';
//import TranslateComponent from "./api/Translate";
import { useMediaQuery } from 'react-responsive';
import useWindowSize from './hooks/useGatsbyWindowSize';

import AnimatedCursor from "react-animated-cursor";
/*import Work_0_1 from "./component/Work_0_1";
import Work_0 from "./component/Work_0";
import Work_1 from "./component/Work_1";
import Work_2 from "./component/Work_2";
import Work_4_1 from "./component/Work_4_1";*/
/*import Work_view from "./component/Work_view";
import Work_all from "./component/Work_all";
import About from "./component/About";
import Blog from "./component/Blog";
import Contact from "./component/Contact";
import Home from "./component/Home";
import Services from "./component/Services";
import Team from "./component/Team";
*/

import Navbar from "./component/Navbar";
import Footer from "./component/Footer";


import { ThemeContext } from './context/ThemeContext';
import './index.css';

function App() {

const {width,height} = useWindowSize();
const vertical = useMediaQuery({ query: `(orientation: portrait)` }); //detecting orientation vs max-width: 821px;

const theme = useContext(ThemeContext);


  useEffect(()=> {
    theme.dispatch({pushOut:false, width:width, height:height, vertical:vertical,type: 'viewport' });

  },[width,vertical])

  useEffect(()=> {
  var touchRegionElement = document.getElementById('app');
  var _cursor_ = touchRegionElement.getElementsByTagName('div')[0];

  function showCoordinates(e) {
     _cursor_.style.left = `${e.touches[0].clientX}`;
     _cursor_.style.top = `${e.touches[0].clientY}`;
  }

  touchRegionElement.addEventListener('touchstart', showCoordinates);
  touchRegionElement.addEventListener('touchend', showCoordinates);
  touchRegionElement.addEventListener('touchmove', showCoordinates);

  },[width])

  return (

    <div id="app">
    <animatedcursor 0="" className="cs" data-cursor="1" showSystemCursor="{true}" trailingSpeed="{12}" innerSize="{18}" outerSize="{8}" color="255, 255, 255" outerAlpha="{0.1}" innerScale="{0.7}" outerScale="{2}" outerStyle="{{" boxShadow:'0="" 3px="" #fff,="" -3px="" 8px="" #f0f,="" #0ff'="" }}="" innerStyle="{{" backgroundColor:="" 'transparent'="" hasBlendMode="{true}" clickables="{[" 'a',="" 'input[type="text" ]',="" 'label[for]',="" 'select',="" 'textarea',="" 'button',="" '.link'="" ]}=""></animatedcursor>
   <navbar></navbar>
   <div id="main" style="{{minHeight:100+'vh',width:'100%'}}" className="bg-gray-900">
<outlet></outlet>

</div>



{/*
    <routes>
      <route path="/" element="{" <Home="" width="{width}" height="{height}" transition="{`${'fadeIn'}`}/"> } />
      <route path="/work" element="{" <Work_all="" transition="{`${'zoomOut'}`}"></route> } >
        <route path=":id" element="{<Work_view" transition="{`${'zoomIn'}`}/">} loader={fetchPosts} />
      </route>
      <route path="/blog" element="{" <Blog="" transition="{`${transitionStage}`}"></route> } />
      <route path="/team" element="{" <Team="" transition="{`${transitionStage}`}"></route> } />
      <route path="/about" element="{" <About="" transition="{`${transitionStage}`}/"> } />
      <route path="/services" element="{" <Services="" transition="{`${transitionStage}`}/"> } />
      <route path="/contact" element="{" <Contact="" transition="{`${transitionStage}`}/"> } />
    </route></route></route></route></routes>
*/}
{/* <translatecomponent></translatecomponent> */}
<footer style="{{position:'fixed',bottom:'0px',left:'0px'}}/">
</footer></div>


  );
}

export default App;
