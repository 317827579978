import React,{useState, useRef, useEffect, useLayoutEffect, useContext} from 'react'
import { Link, useLoaderData, useParams,useLocation, useNavigate } from "react-router-dom"
import { ThemeContext } from '../context/ThemeContext';
import { useDarkMode } from '../customhooks/ThemeHook';
import {SplitText} from "@cyriacbr/react-split-text"
import TitleAnimated from './TitleAnimated'
import { BeakerIcon } from '@heroicons/react/24/solid'
import { Helmet } from 'react-helmet';
import YouTube from "react-youtube";
import gsap from 'gsap'
//import { SplitText } from "gsap/SplitText"
import './work_4.css'

var opts = {}

function WorkView(props) {

  function projectDataAfp(slug,data){
    // the code you're looking for
    var slug = slug;
    var obj = data;
    // iterate over each element in the array
    for (var i = 0; i < obj.length; i++){
      // look for the entry with a matching `code` value
      if (obj[i].slug == slug){
         // we found it
         return (obj[i]);
      }
    }
  }
const location = useLocation();
const navigate = useNavigate();
const theme = useContext(ThemeContext);
const { id } = useParams();


  let projects = (useLoaderData());
  //console.log(projects);


  var bg_image = null;
  var _coef = 10;
  var _coefDefault = 10;
  var _coefMobile = 30;
  var maxScale = 0.4
  var maxScaleDefault = 0.5;
  var maxScaleMobile = 1.2;
  const smoothSc = useRef();
  const growRef = useRef();
  //const featuredImgRef = useRef();

  const [projDataAfp, setProjDataAfp] = useState();
  const [scrollValue, setScrollValue] = useState(0);
  const [featuredImage, setFeaturedImage] = useState();
  const [posterImage, setPosterImage] = useState();
  const [featuredImageOne, setFeaturedImageOne] = useState();
  const [videoId, setVideoId] = useState(null);
  const [isPlayer,setIsPlayer] = useState(null);
  const [darkMode, setDarkMode] = useDarkMode(false);

  const  [pushOut,setPushOut] = useState();

  function outOfView(){
  setPushOut(false);
  }
  //const [direction, setDirection] = useState(null);
  //const [scrollSpeed, setScrollSpeed] = useState(0);
  //const [growIsIntersecting, setGrowIsIntersecting] = useState(false);



  let options = {
    rootMargin: "0px 0px 0px 0px",
    threshold: 0,
  };
  let callback = (entries, observer) => {
    entries.forEach((entry) => {
      // Each entry describes an intersection change for one observed
      // target element:
      //   entry.boundingClientRect
      //   entry.intersectionRatio
      //   entry.intersectionRect
      //   entry.isIntersecting
      //   entry.rootBounds
      //   entry.target
      //   entry.time

      //console.log(entry.isIntersecting);
      var elem = document.querySelector('#pictureGrow') ;
      //elem.style.transform =  'scale('+ 1 +  +')';


        if(entry.isIntersecting){
          //console.log('ok');
          //gsap.to('#pictureGrow',1,{ scale: 3,ease:'expo'})
          //growRef.current.style.position = 'fixed';


        }else{
          //console.log('not ok');
          //growRef.current.style.position = 'inherit';
        }
    });
  };
let observer = new IntersectionObserver(callback, options);

useEffect((props) => {
//gsap.to(featuredImgRef.current,.3,{ delay:0, height:'120vh',ease:'easeIn' });
});

useEffect((props)=> {
observer.observe(growRef.current);
},[growRef]);

useEffect((props)=> {
  if(projDataAfp){
    //console.log(projDataAfp.yt);
    if(projDataAfp.yt.length !== 0){
    setVideoId(projDataAfp.yt.resourceId.videoId);


    opts = {
      width: theme.state.width,
      height: theme.state.height,
      playerVars: {
        autoplay: 1,
        muted: 1,
        controls: 0,
        enablejsapi: 1,
        fs: 0,
        loop: 1,
        rel: 0,
        modestbranding: 1,
        showinfo: 0,
        playlist: projDataAfp.yt.resourceId.videoId ? projDataAfp.yt.resourceId.videoId : 'PLHeUFog1D_MuNeT_OZ0fVtsChMIW0KL-p',
        hd:1,
        //origin: 'frankariza.com'
      },
    };
  }
  }
},[projDataAfp,theme.state.width]);


useLayoutEffect(()=>{

  var _projDataAfp = projectDataAfp(id,projects.items);
  setProjDataAfp(_projDataAfp);
  setFeaturedImage(_projDataAfp.image_thumb_bg.url);
  setPosterImage(_projDataAfp.image_thumb.url);
  setFeaturedImageOne(_projDataAfp.image_thumb_1.url);


  //console.log('yt',_projDataAfp.yt != null ? 'YT ' + _projDataAfp.yt.resourceId.videoId : 'no YT');


if(darkMode === true){
  setDarkMode(!darkMode);
}

  },[location,projDataAfp]);

useLayoutEffect(()=>{

  _coef = theme.state.vertical === true ? _coefMobile : _coefDefault;
  maxScale = theme.state.vertical === true ? maxScaleMobile : maxScaleDefault;
  //bg_image = theme.state.vertical === true ? setFeaturedImage(projData.proj_img_mobile) : setFeaturedImage(projData.proj_img);
  bg_image = featuredImage;
  //console.log('maxscale', maxScale);

  var box = document.getElementById('pictureGrow');
  smoothSc.current.addEventListener( 'scroll', function(e) {

        // normalize scroll position as percentage
        //setScrollValue(e.currentTarget.scrollTop);
        var _scrollValue = e.currentTarget.scrollTop / e.currentTarget.scrollHeight;
        //console.log(_scrollValue);
        var _direction = e.currentTarget.scrollTop - scrollValue < 0 ? -1 : 1;
      //  setDirection(_direction);

        var _scale = gsap.utils.clamp(1, maxScale, _scrollValue * _coef ); // returns 100
        //var _scale = 1 + (e.currentTarget.scrollTop / e.currentTarget.scrollHeight); // returns 100


        //setScrollSpeed(_scale);

        var scrolled = _scale ,
        transformValue = 'scale('+scrolled+')';
        //gsap.to(box,.2,{delay:2, scale: _scale, ease:'expo' });


      window.requestAnimationFrame((e) =>{
    box.style.WebkitTransform = transformValue;
    box.style.MozTransform = transformValue;
    box.style.OTransform = transformValue;
    box.style.transform = transformValue;
      });



  }, false);

},[theme.state.vertical])





  return (


  <div style="{{" background:'#000',="" width:'100vw',height:'100%',display:'block',overflowY:'scroll',position:'fixed',top:0,left:0}}="" id="project" className="{&quot;center" pushOut="" pushOut-"="" +="" darkMode}="">
  <div ref="{smoothSc}" id="-project_parallax" className="{&quot;-parallax" bg-dark="" "="" +="" props.transition}="">

  <div style="{{height:'100vh'," backgroundImage:="" 'url('="" +="" featuredImage="" ')',backgroundPosition:'center',="" backgroundSize:'cover'}}="" className="background-cover">
    <div style="{{display:'flex'," height:'100vh'}}="" className="flex container relative">

    <div style="{{alignSelf:'center',margin:'0" auto'}}="" className="sm:overflow-hidden w-[80%] md:w-[60%] p-4 mt-3">
    <titleanimated level="{1}" id_t="{'project_title'}" classes="{'proj-title" text-left="" pt-6="" lg:pt-31="" xl:pt-80="" pb-4="" text-title="" lg:text-title_lg="" xl:text-title_lg="" leading-6*2'}="" letterSpeed="{0.02}" scale="{1.1}" color="white" title="{projDataAfp" ?="" projDataAfp.name="" :'...'}="" afterTitle="{true}"></titleanimated>
    <div className="afterTitle opacity-0">
    <h3 className="text-white text-left text-2xl font-light pb-10 pl-1">{projDataAfp ? projDataAfp.tax[0].name : ''}</h3>
    <div className="font-light grid grid-cols-4 gap-2 w-4/5 center text-sm pb-20">
      <button className="closeProject1 link" onClick="{()="> {
          setVideoId();
          setDarkMode(!darkMode);
          if(location.state ){
          //console.log(location.state.from);
          navigate("/");
        }
        }}
         style={{zIndex:200,color: darkMode && 'white', background: darkMode && '#db7093'}}>

         {!darkMode ?
           <>
      <svg style="{{transform:'scale(1.5)'}}" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

             <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24"></rect> <path d="M7 17L16.8995 7.10051" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M7 7.00001L16.8995 16.8995" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
             <span style="{{marginTop:'-30px'}}">BACK</span>
           </>
         : ''}

       </button>
    {/*
      <div style="{{whiteSpace:" 'pre-line',verticalAlign:="" 'bottom'}}="" className="text-white text-left leading-5"><span className="font-medium">Client</span><br/>{projData.proj_header.client}</div>
      <div style="{{whiteSpace:" 'pre-line',verticalAlign:="" 'bottom'}}="" className="text-white text-left leading-5"><span className="font-medium">Services</span><br/>{projData.proj_header.services}</div>
      <div style="{{whiteSpace:" 'pre-line',verticalAlign:="" 'bottom'}}="" className="text-white text-left leading-5"><span className="font-medium">industries</span><br/>{projData.proj_header.industry}</div>
      <div style="{{whiteSpace:" 'pre-line',verticalAlign:="" 'bottom'}}="" className="text-white text-left leading-5"><span className="font-medium">Date</span><br/>{projData.proj_header.date}</div>
      */}
    </div>
  {/*  <p style="{{whiteSpace:" 'pre-line',verticalAlign:="" 'bottom'}}="" className="hidden md:block text-white text-left text-1xl lg:text-4l font-Regular w-4/5 xl:w-3/5">{projData.proj_excerpt}</p> */}

    </div>
  </div>

  </div>
</div>

<section className="container">
<div className="grid grid-cols-1 gap-1 mt-8 xl:mt-12 xl:gap-3 md:grid-cols-2 xl:grid-cols-2">

<div className="col-span-2">
  <titleanimated level="{2}" id_t="{'project_headline'}" classes="{'p-6" proj-title="" pt-16="" pb-6="" text-title="" lg:text-title_lg="" xl:text-title_lg="" leading-8*2="" opacity-3'}="" letterSpeed="{0.02}" scale="{0.7}" color="white" title="{projDataAfp" ?="" projDataAfp.name="" :'...'}="" afterTitle="{true}/">

  <div style="{{opacity:'.8'}}" className="afterTitle pl-4 pb-6">
  <div className="p-6 text-white" dangerouslySetInnerHTML="{{__html:" projDataAfp="" ?="" projDataAfp.description="" :'...'="" }}=""></div>
</div>
</titleanimated></div>

<div className="col-span-1">
<img style="{{maxWidth:'300px',margin:'0" auto'}} className="pt-24 p-6" src="{posterImage}" alt="">
</div>

</div>
</section>


<section id="videoSection" className="{&quot;bg-dark" "="" +="" props.transition}="">
{ videoId ? <youtube videoId="{videoId}" onReady="{(e)=">{
            const player = e.target;
            player.seekTo(0);
            setIsPlayer(player);
          }}
          opts={opts}
          className={'ytPlayer'}
          style={{width:'100%',height: theme.state.height + 'px'}}
        />
        :
        ''
      }
</youtube></section>


<section className="{&quot;bg-dark" "="" +="" props.transition}="">
<div className="container px-6 py-10 mx-auto bg-black">
    <h1 className="text-3xl font-light text-center text-white capitalize lg:text-4xl "><span className="text-blue-600">DATA</span></h1>

    <div className="grid grid-cols-1 gap-2 mt-8 xl:mt-12 xl:gap-8 md:grid-cols-2 xl:grid-cols-2">
        <div 39="" style="{{backgroundColor:" 'rgb(39="" 42)'}}="" className="flex flex-col items-center p-6 space-y-3 text-center rounded-xl">
            <span className="inline-block p-3 text-white bg-magenta-dark rounded-full">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="{1.5}" stroke="currentColor" className="size-6">

<path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
</svg>

            </span>

            <h1 className="text-2xl text-white font-light capitalize dark:text-white">Artistic data</h1>

      { projDataAfp ?  <div className="text-white" dangerouslySetInnerHTML="{{__html:" projDataAfp="" ?="" projDataAfp.desc_art="" :'...'="" }}=""></div> : ''}

        </div>

        <div 39="" style="{{backgroundColor:" 'rgb(39="" 42)'}}="" className="flex flex-col items-center p-6 space-y-3 text-center bg-zinc-950 rounded-xl dark:bg-zinc-950">
            <span className="inline-block p-3 text-white bg-magenta-dark rounded-full">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="{1.5}" stroke="currentColor" className="size-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"></path>
</svg>

            </span>

            <h1 className="text-2xl text-white font-light capitalize dark:text-white">Technical data</h1>

            <div className="text-white" dangerouslySetInnerHTML="{{__html:" projDataAfp="" ?="" projDataAfp.desc_tec="" :'...'="" }}=""></div>

        </div>


    </div>
</div>
</section>




<div className="container overflow-hidden">
<div id="pictureGrow" className="galleryScale">
  <img ref="{growRef}" className="w-full" src="{featuredImageOne}" alt="">
</div>
</div>

</div>
</div>
  )
}

export default WorkView
