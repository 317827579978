import React from 'react'

function Legal(props) {
  return (
    <div className="{&quot;flex" flex-col="" justify-between="" max-w-xl="" px-4="" mx-auto="" lg:pt-16="" lg:flex-row="" md:px-8="" lg:max-w-screen-xl="" "="" +="" props.transition}="">
  <div className="pt-16 mb-16 lg:mb-0 lg:pt-32 lg:max-w-lg lg:pr-5">
    <div className="max-w-xl mb-6">
      
      <h2 className="max-w-lg mb-6 font-sans text-3xl font-light tracking-tight  sm:text-4xl sm:leading-none">
        LEGAL NOTICE AND GENERAL CONDITIONS OF USE<br>
      </h2>
      <h3 className="text-3xl">w<span className="magenta">ww.fran</span>kariza.com</h3>

   <p className="pb-6">In compliance with the duty of information provided in Law 34/2002 of Services of the Information Society and Electronic Commerce (LSSI-CE) of July 11, the following general information of this website is provided below:</p>

   <p className="pb-6">The ownership of this website, www.frankariza.com, (hereinafter Website) is held by: </p>

   <p className="pb-6">AF Films &amp; Productions, SLU, with C.I.F: B16823056, and whose contact details are:</p>

   <p className="pb-6">Address:
  	<br/>Calle Rejas N° 2
  	<br/>Floor 4, Door 24
  	<br/>28821 COSLADA (MADRID)</p>

   <p className="pb-6">Contact email: <a href="mailto:info@frankariza.com">info@frankariza.com</a></p>

  <h3>II. GENERAL TERMS AND CONDITIONS OF USE</h3>

  <h4>The object of the conditions: The Web Site</h4>

   <p className="pb-6">The purpose of these General Conditions of Use (hereinafter, Conditions) is to regulate access to and use of the Website. For the purposes of these Terms and Conditions, the Website shall be understood as: the external appearance of the screen interfaces, both statically and dynamically, i.e. the navigation tree; and all the elements integrated both in the screen interfaces and in the navigation tree (hereinafter, Contents) and all those online services or resources offered to Users (hereinafter, Services).</p>

   <p className="pb-6"> reserves the right to modify, at any time and without prior notice, the presentation and configuration of the Web Site and the Content and Services that may be incorporated therein. The User acknowledges and agrees that at any time may interrupt, disable and / or cancel any of these elements that are integrated into the Website or access to them.</p>

   <p className="pb-6">Access to the Web Site by the User is free and, as a general rule, is free of charge without the User having to provide any consideration in order to enjoy it, except for the cost of connection through the telecommunications network provided by the access provider contracted by the User.</p>

   <p className="pb-6">The use of the Contents does not require any prior subscription or registration.</p>

  <h4>The User</h4>

   <p className="pb-6">The access, navigation and use of the Web Site, confers the condition of User, so you accept, from the moment you start browsing the Web Site, all the Conditions set forth herein, as well as its subsequent modifications, without prejudice to the application of the corresponding legal regulations of mandatory compliance as the case may be. Given the relevance of the above, the User is recommended to read them every time he/she visits the Website.</p>

   <p className="pb-6">The Web Site provides a great diversity of information, services and data. The User assumes responsibility for the correct use of the Web Site. This responsibility shall extend to:</p>

  <ul>
  	<li>A use of the information, Contents and/or Services and data offered by without that is contrary to the provisions of these Conditions, the Law, morality or public order, or that in any other way may involve injury to the rights of third parties or the same operation of the Website.</li>
  	<li>The veracity and legality of the information provided by the User in the forms extended by for access to certain Content or Services offered by the Website. In any case, the User will immediately notify about any fact that allows the improper use of the information registered in such forms, such as, but not only, the theft, loss, or unauthorized access to identifiers and / or passwords, in order to proceed to its immediate cancellation.</li>
  </ul>

   <p className="pb-6">The mere access to this Web Site does not imply the establishment of any type of commercial relationship between the User and this Web Site.</p>

   <p className="pb-6">In compliance with the legislation in force, this Website is intended for all persons, regardless of their age, who may access and/or browse the pages of the Website.</p>

  <h3>III. ACCESS AND NAVIGATION ON THE WEB SITE: EXCLUSION OF WARRANTIES AND LIABILITY</h3>

   <p className="pb-6"> does not guarantee the continuity, availability and usefulness of the Web Site, or of the Contents or Services. will make every effort to ensure the proper functioning of the Web Site, however, it is not responsible for and does not guarantee that access to this Web Site will not be uninterrupted or error-free.</p>

   <p className="pb-6">Nor is it responsible for or guarantees that the content or software that can be accessed through this Web Site is free of error or cause damage to the computer system (software and hardware) of the User. In no event shall be liable for any loss, damage or harm of any kind arising from accessing, browsing and using the Website, including, but not limited to, those caused to computer systems or those caused by the introduction of viruses.</p>

   <p className="pb-6"> is not responsible for any damage that may be caused to users by improper use of this Web Site. In particular, it shall not be liable in any way for any telecommunications failures, interruptions, faults or defects that may occur.</p>

  <h3>IV. LINKING POLICY</h3>

   <p className="pb-6">It is informed that the Website makes or may make available to Users means of linking (such as, among others, links, banners, buttons), directories and search engines that allow Users to access websites belonging to and/or managed by third parties.</p>

   <p className="pb-6">The installation of these links, directories and search engines on the Web Site is intended to facilitate Users' search for and access to information available on the Internet, without being considered a suggestion, recommendation or invitation to visit them.</p>

   <p className="pb-6"> does not itself or through third parties offer or market the products and/or services available on such linked sites.</p>

   <p className="pb-6">Likewise, it does not guarantee the technical availability, accuracy, veracity, validity or legality of sites outside its property that can be accessed through the links.</p>

   <p className="pb-6"> in no way reviews or controls the content of other web sites, nor does it endorse, review or endorse the products and services, content, files and any other material on such linked sites.</p>

   <p className="pb-6"> assumes no liability for any damages that may arise from the access, use, quality or legality of the contents, communications, opinions, products and services of websites not managed by and linked to this Web Site.</p>

   <p className="pb-6">The User or third party who makes a hyperlink from a web page of another, different, web site to the Web Site of shall know that:</p>

   <p className="pb-6">No reproduction - in whole or in part - of any of the Contents and/or Services of the Website is permitted without the express permission of .</p>

   <p className="pb-6">No false, inaccurate or incorrect statement about the Web Site, nor about the Contents and/or Services of the Web Site is permitted.</p>

   <p className="pb-6">With the exception of the hyperlink, the website where such hyperlink is established shall not contain any element of this Website protected as intellectual property by the Spanish legal system, unless expressly authorized by .</p>

   <p className="pb-6">The establishment of the hyperlink does not imply the existence of a relationship between the owner of the website from which it is made, nor the knowledge and acceptance of the contents, services and/or activities offered on said website, and vice versa.</p>

  <h3>V. INTELLECTUAL AND INDUSTRIAL PROPERTY</h3>

   <p className="pb-6"> by itself or as an assignee, owns all intellectual and industrial property rights of the Website, as well as the elements contained therein (including but not limited to images, sound, audio, video, software or text, trademarks or logos, color combinations, structure and design, selection of materials used, computer programs necessary for its operation, access and use, etc.). They are, therefore, works protected as intellectual property by the Spanish legal system, being applicable to them both the Spanish and Community regulations in this field, as well as the international treaties related to the matter and signed by Spain.</p>

   <p className="pb-6">All rights reserved. Pursuant to the provisions of the Intellectual Property Law, the reproduction, distribution and public communication, including the mode of making available, of all or part of the contents of this website, for commercial purposes, in any medium and by any technical means, without the authorization of .</p>

   <p className="pb-6">The User undertakes to respect the intellectual and industrial property rights of . You may view the elements of the Website or even print, copy and store them on the hard drive of your computer or any other hardware provided it is solely for personal use. The User, however, may not remove, alter or manipulate any protection device or security system installed on the Website.</p>

   <p className="pb-6">In the event that the User or third party considers that any of the Contents of the Web Site involves a violation of the rights of protection of intellectual property, he/she should immediately notify this through the contact details in the GENERAL INFORMATION section of this Legal Notice and General Conditions of Use.</p>

  <h3>VI. LEGAL ACTIONS, APPLICABLE LAW AND JURISDICTION</h3>

   <p className="pb-6"> reserves the right to file the civil or criminal actions it deems necessary for the improper use of the Website and Contents, or for the breach of these Conditions.</p>

   <p className="pb-6">The relationship between the User and shall be governed by the regulations in force and applicable in the Spanish territory. Should any controversy arise in relation to the interpretation and/or application of these Conditions, the parties shall submit their disputes to the ordinary jurisdiction, submitting themselves to the judges and courts that correspond according to law.</p>

    </div>

  </div>
  <div className="pt-16 p-6 mb-16 lg:mb-0 lg:pt-32 lg:max-w-lg lg:pr-5">
<img style="{{transform:'scale(2)'}}" className="logoIconInline " src="/img/af-films-logo.svg" alt="">  </div>
</div>
  )
}

export default Legal
