import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useIntersection } from './intersectionObserver';
import './imageRenderer.css';

const ImageRenderer = ({ url, width, height, alt_tag, styleclass }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isInView, setIsInView] = useState(false);
  //const v = (Math.random()*(256)|0).toString(16);
  const v = (Math.random()*(256)|0).toString(16);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="{&quot;_image-container" "="" +="" styleclass}="" ref="{imgRef}" style="{{" paddingBottom:="" `${(height="" width)="" *="" 100}%`,="" width:="" '100%',="" backgroundColor:="" '#'+="" v="" }}="">
      {isInView && (
          <img className="{classnames('_image" ',="" {="" '_isLoaded="" _isVisible':="" !!isLoaded="" })}="" src="{url}" onLoad="{handleOnLoad}" alt="{alt_tag}">

      )}
    </div>
  );
};

export default ImageRenderer;
