import React from 'react'
import {  Link } from "react-router-dom";

function Services(props) {
  return (

      <section className="{&quot;bg-white" "="" +="" props.transition}="">
      <div className="container px-6 py-10 mx-auto">
          <h1 className="text-3xl font-light text-center text-gray-800 capitalize lg:text-4xl ">Animating Dreams with <span className="magenta">Pictures in Motion</span></h1>
          <h3 className="text-center text-xl">Services</h3>
          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-magenta rounded-xl dark:bg-magenta">
                  <span className="inline-block p-3 bg-magenta-dark text-white rounded-full dark:text-white dark:bg-magenta-dark">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="{2}" stroke="currentColor" className="size-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"></path>
</svg>

                  </span>

                  <h1 className="text-2xl font-light text-white capitalize dark:text-white">Acquisition of Rights</h1>

                  <p className="text-gray-100 dark:text-gray-500">
                      We identify and acquire films of high quality and commercial potential.
                  </p>

                  <link to="../catalogo" className="flex items-center -mx-1 text-md text-white capitalize transition-colors duration-300 transform dark:text-white hover:underline hover:text-white dark:hover:text-white">
                      <span className="mx-1">Catalog</span>
                      <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  
              </div>

              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl dark:bg-gray-100">
                  <span className="inline-block p-3 text-white bg-magenta rounded-full dark:text-white dark:bg-magenta">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="{1.8}" stroke="currentColor" className="size-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"></path>
</svg>

                  </span>

                  <h1 className="text-2xl font-light text-gray-700 capitalize dark:text-gray-700">Marketing and Promotion</h1>

                  <p className="text-gray-500 dark:text-gray-700">
                      We develop customized film marketing strategies for each film, maximizing its reach and visibility.
                  </p>

                  <link to="../contacto" className="flex items-center -mx-1 text-md magenta-dark capitalize transition-colors duration-300 transform dark:magenta-dark hover:underline hover:magenta-dark dark:hover:magenta-dark">
                      <span className="mx-1">Contact</span>
                      <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  
              </div>

              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl dark:bg-gray-100">
                  <span className="inline-block p-3 bg-magenta text-white rounded-full dark:text-white dark:bg-magenta">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
                      </svg>
                  </span>

                  <h1 className="text-2xl font-light text-gray-700 capitalize dark:text-gray-700">Multichannel Distribution</h1>

                  <p className="text-gray-500 dark:text-gray-700">
                      We offer distribution in theaters, streaming platforms, television and physical formats, ensuring the availability of content in all relevant media.
                  </p>

                  <link to="../contacto" className="flex items-center -mx-1 text-md magenta-dark capitalize transition-colors duration-300 transform dark:magenta-dark hover:underline hover:magenta-dark dark:hover:magenta-dark">
                      <span className="mx-1">Contact</span>
                      <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    
              </div>
          </div>
      </div>
    </section>
  )
}

export default Services
